import { useEffect, useState } from "react";
import classNames from "classnames";
import { BiChevronDown as ChevronDown } from "react-icons/bi";
import { BsCircleFill } from "react-icons/bs";
import { Icon } from ".";

interface Item {
  label: string;
  id: string | number;
  urlPrefix?: string;
}

interface SelectProps {
  disabled?: boolean;
  onChange: (item: Item) => void;
  selectedItem?: Item;
  leftAlign?: boolean;
  items: Item[];
  label?: string;
  size?: "lg" | "md";
  reset?: () => void;
  width?: number | string;
}
export const Select = ({
  leftAlign,
  items,
  label,
  onChange,
  size = "md",
  selectedItem,
  disabled,
  reset,
  width,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const itemChange = (item: Item) => {
    if (item && selectedItem?.id !== item.id) {
      onChange(item);
    }
    setIsOpen(false);
  };

  return (
    <div style={width ? { width: width } : {}} className="w-fit">
      <div
        className={classNames(
          "w-auto min-w-[4rem] bg-white border rounded-lg flex justify-between items-center px-2 py-1 gap-4",
          { "border-prime": isOpen },
          { "max-w-xs": !width },
          { "hover:border-prime cursor-pointer": !disabled || items.length > 0 },
          { "cursor-not-allowed": disabled || items.length === 0 },
          { "px-4 py-2.5 max-w-lg gap-4": size === "lg" }
        )}
        onClick={(e) => {
          !disabled && items.length > 0 && setIsOpen((prev) => !prev);
        }}>
        {!!reset && selectedItem && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              reset();
            }}
            className="flex justify-center items-center p-0.5 hover:text-white bg-gray-200 hover:bg-gray-800 rounded-full">
            <Icon value="close" />
          </div>
        )}
        <div>{selectedItem ? selectedItem.label : label}</div>
        <div className="flex justify-between items-center">
          <ChevronDown
            className={classNames("transition-transform duration-200", {
              "rotate-180": isOpen,
            })}
          />
        </div>
      </div>
      {isOpen && (
        <SelectArea
          size={size}
          leftAlign={leftAlign}
          selectedItem={selectedItem}
          onSelectionChange={(item) => itemChange(item)}
          items={items}
        />
      )}
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-transparent z-[100]"
          onClick={() => setIsOpen(false)}></div>
      )}
    </div>
  );
};

interface SelectAreaProps {
  leftAlign?: boolean;
  items: Item[];
  onSelectionChange: (item: Item) => void;
  selectedItem: Item | undefined;
  size: "lg" | "md";
}
const SelectArea = ({
  items,
  onSelectionChange,
  selectedItem,
  leftAlign,
  size,
}: SelectAreaProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
    setTimeout(() => {
      setIsReady(true);
    }, 300);
  }, []);
  return (
    <div
      className={classNames(
        "z-[101] absolute min-w-[4rem] cursor-default bg-white rounded-xl py-1 shadow-card border h-auto ease-out transition-[max-height,overflow] duration-[300ms] max-h-0",
        isReady ? "overflow-auto" : "overflow-hidden",
        { "max-h-[20rem]": isLoaded },
        { "right-0": leftAlign },
        { "w-fit grid": size === "lg" }
      )}>
      {items.map((x, i) => {
        const isSelected = selectedItem?.id === x.id;
        return (
          <div
            onClick={() => onSelectionChange(x)}
            className={classNames(
              "w-full select-none flex justify-between hover:bg-gray-200 py-1 px-2 gap-2 cursor-pointer items-center",
              {
                "font-bold": isSelected,
              },
              { "gap-4 min-w-[220px] py-3": size === "lg" }
            )}
            key={i}>
            <div className="flex items-center">{x.label}</div>
            <div className="flex items-center">
              {isSelected && <BsCircleFill className="text-[6px]" />}
            </div>
          </div>
        );
      })}
    </div>
  );
};
