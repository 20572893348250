import { SVGAttributes, cloneElement } from "react";
import classNames from "classnames";
import { LoadingSpinner } from "components/LoadingIndicator/LoadingIndicator";
import { useGetCountryFlag } from "hooks/DNB/useGetCountryFlag";
import { useGetIcon, useIcon } from "hooks/DNB/useGetIcon";
import { TbRadar2 } from "react-icons/tb";

export const SectorIcon = ({ value }: { value: string }) => {
  const icon = useGetIcon(value);
  return icon;
};

export const CountryIcon = ({ value }: { value: string }) => {
  const icon = useGetCountryFlag(value);
  return icon;
};

interface IconProps extends SVGAttributes<SVGElement> {
  value: string;
  loading?: boolean;
}
export const Icon = ({ value, loading, ...restProps }: IconProps) => {
  const icon = useIcon(value);
  if (loading)
    return (
      <LoadingSpinner {...restProps} className={classNames("cursor-wait", restProps.className)} />
    );
  return cloneElement(icon, { ...restProps });
};
