import { useGetPortfolioOptions, TOTAL_INVESTMENTS_OPTION_ID } from "hooks/useGetPortfolioOptions";
import { PortfolioOption } from "layouts/PortfolioNavigationHeaderLayout/PortfolioNavigationHeader/PortfolioNavigationHeader";
import { useNavigateToPortfolioTab } from "layouts/PortfolioNavigationHeaderLayout/PortfolioNavigationHeader/useNavigateToPortfolioTab";
import { useRedirectIfOnlyOnePortfolio } from "layouts/PortfolioNavigationHeaderLayout/PortfolioNavigationHeader/useRedirectIfOnlyOnePortfolio";
import { Navigate, useParams } from "react-router-dom";
import { Select } from "../Select";

export const PortfolioSelector = ({ close }: { close?: () => void }) => {
  const portfolioOptions = useGetPortfolioOptions();
  const { portfolioId: portfolioIdUrl } = useParams();
  const portfolioId = portfolioIdUrl ? parseInt(portfolioIdUrl, 10) : TOTAL_INVESTMENTS_OPTION_ID;
  const navigateToPortfolioTab = useNavigateToPortfolioTab();
  useRedirectIfOnlyOnePortfolio();
  const onPortfolioChange = (selectedOption: PortfolioOption) => {
    navigateToPortfolioTab(selectedOption.urlPrefix);
    close && close();
  };

  return (
    <div className="flex items-center">
      <PortfolioSelect
        portfolioOptions={portfolioOptions}
        portfolioId={portfolioId}
        onPortfolioChange={onPortfolioChange}
      />
    </div>
  );
};

interface PortfolioSelectorProps {
  portfolioOptions: PortfolioOption[];
  portfolioId: number;
  onPortfolioChange: (selectedOption: PortfolioOption) => void;
}
export const PortfolioSelect = ({
  portfolioOptions,
  portfolioId,
  onPortfolioChange,
}: PortfolioSelectorProps) => {
  const items = portfolioOptions.map((x) => ({ id: x.id, label: x.label, urlPrefix: x.urlPrefix }));

  if (
    portfolioId !== TOTAL_INVESTMENTS_OPTION_ID &&
    !portfolioOptions.some((x) => x.id === portfolioId)
  ) {
    return <Navigate to="/" replace />;
  }

  return portfolioOptions.length > 0 ? (
    <Select
      items={items}
      size="lg"
      selectedItem={items.find((x) => x.id === portfolioId)}
      onChange={(i) =>
        onPortfolioChange({
          id: parseInt(i.id.toString()),
          label: i.label,
          urlPrefix: i.urlPrefix || "",
        })
      }
    />
  ) : (
    <div />
  );
};
