import { Portfolio, useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { useParams } from "react-router-dom";

export enum canTradeMode {
  ANY,
  SELECTED,
  SELECTED_ANY,
}

export const tradableTag = "anbefalt";
export const TradePermissionGroup = "CP_TRADING" as const;
export const SavingPermissionGroup = "CP_SAVING" as const;

export const isPortfolioTradable = (portfolio: Portfolio) =>
  portfolio.portfolioGroups.some((group) => group.code === TradePermissionGroup);

/*
 * Checks if user or portfolio is eligible to trade
 * @param mode: mode to apply when checking if eligible to trade
 * SELECTED - check only if the currently selected portfolio can trade
 * ANY - check if any of the user's portfolios can trade
 * SELECTED_ANY - use SELECTED_ONLY if there is a selected portfolio, else do ANY
 * @return boolean - whether trading is allowed
 */
export const useCanTrade = (mode = canTradeMode.SELECTED) => {
  const { portfolioId } = useParams();
  const { selectedContactId } = useGetContractIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(false, selectedContactId);

  const isAnyPortfolioTradable = portfolios.some(isPortfolioTradable);
  const selectedPortfolio = portfolios.filter(
    (portfolio) => portfolioId !== undefined && portfolio.id === parseInt(portfolioId, 10)
  );
  const isSelectedPortfolioTradable = selectedPortfolio.some(isPortfolioTradable);

  switch (mode) {
    case canTradeMode.ANY:
      return isAnyPortfolioTradable;
    case canTradeMode.SELECTED:
      return isSelectedPortfolioTradable;
    case canTradeMode.SELECTED_ANY:
      if (portfolioId !== undefined) return isSelectedPortfolioTradable;
      return isAnyPortfolioTradable;
    default:
      return false;
  }
};
export enum canSaveMode {
  ANY,
  SELECTED,
  SELECTED_ANY,
}
export const isPortfolioSave = (portfolio: Portfolio) =>
  portfolio.portfolioGroups.some((group) => group.code === SavingPermissionGroup);
export const useCanSave = (mode = canSaveMode.SELECTED) => {
  const { portfolioId } = useParams();
  const { selectedContactId } = useGetContractIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(false, selectedContactId);

  const isAnyPortfolio = portfolios.some(isPortfolioSave);
  const selectedPortfolio = portfolios.filter(
    (portfolio) => portfolioId !== undefined && portfolio.id === parseInt(portfolioId, 10)
  );
  const isSelected = selectedPortfolio.some(isPortfolioSave);

  switch (mode) {
    case canSaveMode.ANY:
      return isAnyPortfolio;
    case canSaveMode.SELECTED:
      return isSelected;
    case canSaveMode.SELECTED_ANY:
      if (portfolioId !== undefined) return isSelected;
      return isAnyPortfolio;
    default:
      return false;
  }
};
