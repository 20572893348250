import classNames from "classnames";
import { BiCoinStack } from "react-icons/bi";
import {
  BsFillDropletFill,
  BsBell,
  BsHouseDoor,
  BsBank2,
  BsPiggyBankFill,
  BsGraphUp,
  BsFileEarmarkPdfFill,
  BsFilter,
} from "react-icons/bs";
import { CgSmartHomeRefrigerator } from "react-icons/cg";
import { FaIndustry, FaCoins } from "react-icons/fa";
import { GiHealthNormal } from "react-icons/gi";
import { GrPersonalComputer } from "react-icons/gr";
import { HiDocument } from "react-icons/hi";
import { MdOutlineShoppingCart, MdOutlineElectricalServices, MdClose } from "react-icons/md";
import { RiCoinsLine } from "react-icons/ri";
import { TbRadar2 } from "react-icons/tb";

export const useIcon = (value: string) => {
  const icon = value.toLowerCase();
  switch (icon) {
    case "filter":
      return <BsFilter />;
    case "close":
      return <MdClose />;
    case "pdf":
      return <BsFileEarmarkPdfFill />;
    case "report":
      return <BsFileEarmarkPdfFill />;
    case "bank":
      return <BsBank2 />;
    case "cash":
      return <FaCoins />;
    case "return":
      return <BsGraphUp />;
    case "portfolio":
      return <BsPiggyBankFill />;
    case "allocation":
      return <BsFillDropletFill />;
    case "health":
      return <GiHealthNormal />;
    case "industry":
      return <FaIndustry />;
    case "shoppingcart":
      return <MdOutlineShoppingCart />;
    case "refrigerator":
      return <CgSmartHomeRefrigerator />;

    default:
      return <BiCoinStack />;
  }
};

export const useGetIcon = (groupCode: string, size?: string | number, onClick?: () => void) => {
  if (groupCode === "OTHER" || !groupCode || groupCode.length < 2) return <BiCoinStack />;

  switch (groupCode) {
    case "PDF":
      return <HiDocument style={{ fontSize: size }} />;
    case "REPORT":
      return (
        <BsFileEarmarkPdfFill
          className={classNames({ "cursor-pointer hover:text-prime": onClick })}
          onClick={() => onClick && onClick()}
          style={{ fontSize: size }}
        />
      );
    case "BANK":
      return <BsBank2 style={{ fontSize: size }} />;
    case "CASH":
      return <FaCoins style={{ fontSize: size }} />;
    case "RETURN":
      return <BsGraphUp style={{ fontSize: size }} />;
  }

  const code = groupCode.substring(0, 2);
  switch (code) {
    case "10":
      return <BsFillDropletFill />;
    case "15":
      return <BsBell />;
    case "20":
      return <FaIndustry />;
    case "25":
      return <MdOutlineShoppingCart />;
    case "30":
      return <CgSmartHomeRefrigerator />;
    case "35":
      return <GiHealthNormal />;
    case "40":
      return <RiCoinsLine />;
    case "45":
      return <GrPersonalComputer />;
    case "50":
      return <TbRadar2 />;
    case "55":
      return <MdOutlineElectricalServices />;
    case "60":
      return <BsHouseDoor />;

    default:
      return <BiCoinStack />;
  }
};
