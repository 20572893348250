import { SVGProps } from "react";
import { ReactComponent as Spinner } from "assets/spinner.svg";
import classNames from "classnames";
import { Center } from "../Center/Center";

interface LoadingIndicatorProps {
  center?: boolean;
  small?: boolean;
}

export const LoadingIndicator = ({ center = false, small = false }: LoadingIndicatorProps) => {
  const SpinnerNode = (
    <Spinner
      className={classNames(
        "text-gray-200 animate-spin fill-prime",
        small ? "w-4 h-14" : "w-16 h-16"
      )}
    />
  );
  return center ? <Center>{SpinnerNode}</Center> : SpinnerNode;
};

interface LoadingSpinnerProps extends SVGProps<SVGSVGElement> {
  center?: boolean;
}
export const LoadingSpinner = ({ center, ...restProps }: LoadingSpinnerProps) => {
  const SpinnerNode = (
    <Spinner
      {...restProps}
      className={classNames("text-gray-200 w-4 h-4 animate-spin fill-prime", restProps.className)}
    />
  );
  return center ? <Center>{SpinnerNode}</Center> : SpinnerNode;
};
