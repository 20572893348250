import { HTMLProps, ReactNode } from "react";
import classNames from "classnames";

interface CardProps extends HTMLProps<HTMLDivElement> {
  children: ReactNode;
  heading?: ReactNode;
  options?: Options;
}

export const Card = ({
  children,
  heading,
  options = { heading: { size: "md", hideBorder: false } },
  ...restProps
}: CardProps) => {
  return (
    <div
      {...restProps}
      className={classNames(
        "w-full h-full rounded-xl shadow-card flex flex-col justify-start overflow-hidden",
        options.colors?.background && !options.colors?.gradientBg
          ? options.colors?.background
          : "bg-white",

        {
          "bg-gradient-to-b from-green-400 via-green-400/90 to-green-500":
            options.colors?.gradientBg && options.colors?.background,
        },
        restProps.className
      )}>
      {heading && (
        <div
          className={classNames(
            "w-full flex justify-start items-center overflow-hidden whitespace-nowrap text-ellipsis",
            options.heading?.hideBorder ? "" : "border-b",
            options.heading?.colors?.text ? options.heading?.colors?.text : "text-inherit",
            { "p-3 min-h-[3rem] max-h-[3rem]": options?.heading?.size === "md" },
            { "p-1.5 min-h-[40px]": options?.heading?.size === "sm" },
            {
              "text-xl font-bold": typeof heading === "string" && options?.heading?.size === "md",
            },
            { "text-lg font-bold": typeof heading === "string" && options?.heading?.size === "sm" }
          )}>
          {typeof heading === "string" ? (
            <div className="overflow-hidden relative whitespace-nowrap text-ellipsis">
              {heading}
            </div>
          ) : (
            heading
          )}
        </div>
      )}
      {children}
    </div>
  );
};

interface Options {
  heading?: Heading;
  colors?: Colors;
}
interface Heading {
  size?: "md" | "sm" | string;
  colors?: Colors;
  hideBorder?: boolean;
}
interface Colors {
  text?: string;
  background?: string;
  gradientBg?: boolean;
}
