import { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { LoadingSpinner } from "components/LoadingIndicator/LoadingIndicator";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { SelectedContact, useGetContractIdData } from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import { BiChevronDown } from "react-icons/bi";
import { BsPersonFill } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { keycloakService } from "services/keycloakService";
import { Button, TextInput } from "..";
import { useGetRepresentees } from "./api/useGetRepresentees";

export const ContactSelect = () => {
  const { selectedContact, setSelectedContactId, setSelectedContact } = useGetContractIdData();
  const { linkedContact } = useKeycloak();
  const [isOpen, setIsOpen] = useState(false);
  const setContact = (contact: SelectedContact) => {
    setSelectedContact(contact);
    setSelectedContactId(contact.id);
    setIsOpen(false);
  };
  return (
    <>
      <div className={classNames("relative", { "z-[101]": isOpen })}>
        <div
          onClick={() => setIsOpen((prev) => !prev)}
          className={classNames(
            "rounded-full group bg-gray-200 border hover:border-gray-800 h-10 flex justify-between items-center gap-3 p-1 cursor-pointer"
          )}>
          <BiChevronDown className="text-gray-800" />
          <div
            className={classNames(
              isOpen ? "max-w-[12rem]" : "max-w-[5rem]",
              "group-hover:max-w-[12rem] whitespace-nowrap overflow-hidden text-ellipsis select-none transition-all"
            )}>
            {selectedContact.userName}
          </div>

          <div
            className={classNames(
              "flex justify-center rounded-full h-7 w-7 items-center bg-companyColor transition-transform duration-300",
              { "rotate-180": isOpen }
            )}>
            {isOpen ? <MdClose className="text-white" /> : <BsPersonFill className="text-white" />}
          </div>
        </div>
        {isOpen && (
          <SearchContacts
            onClose={() => setIsOpen(false)}
            onSelect={(contact) => setContact(contact)}
            selectedContact={selectedContact}
            linkedContact={linkedContact}
          />
        )}
      </div>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-transparent z-[100]"
          onClick={() => setIsOpen(false)}></div>
      )}
    </>
  );
};

interface SearchContactsProps {
  selectedContact: SelectedContact;
  linkedContact: string | undefined;
  onSelect: (contact: SelectedContact) => void;
  onClose: () => void;
}
const SearchContacts = ({
  selectedContact,
  linkedContact,
  onClose,
  onSelect,
}: SearchContactsProps) => {
  const [onlyDirect, setOnlyDirect] = useState(true);
  const { data, loading } = useGetRepresentees(linkedContact, onlyDirect);
  const logOut = () => keycloakService.onAuthLogout();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  const { t } = useModifiedTranslation();
  const close = useCallback(() => {
    setIsLoaded(false);
    onClose();
  }, [setIsLoaded, onClose]);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        close();
      }
    });
    return () => {
      document.body.style.overflowY = "auto";
      document.removeEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          close();
        }
      });
    };
  }, [close]);

  const setSelectedContact = (contact: SelectedContact) => {
    onSelect(contact);
    close();
  };

  const searchRef = useRef<HTMLInputElement>(null);
  const [searchName, setSearchName] = useState("");

  const LinkedContact = {
    id: data?.id ?? "",
    contactId: data?.contactId ?? "",
    userName: data?.name ?? "",
    initials: data?.name ?? "",
  };

  return (
    <>
      <div
        id="userMenuPopup"
        className={classNames(
          "overflow-y-auto z-[101] absolute cursor-default max-h-0 right-0 mt-1 bg-white rounded-lg shadow-card border h-auto overflow-hidden transition-[max-height]",
          { "max-h-[40rem]": isLoaded }
        )}>
        <div className="flex justify-between p-2">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOnlyDirect(false);
              setSearchName(searchRef.current?.value ?? "");
            }}>
            <TextInput placeholder={t("component.contactSelect.search")} ref={searchRef} />
          </form>
        </div>
        {loading && (
          <div className="flex justify-center items-center">
            <LoadingSpinner className="w-12 h-12" />
          </div>
        )}
        {!loading && data && (
          <ContactRow
            isSelected={selectedContact.id === linkedContact}
            contact={LinkedContact}
            onClick={(contact) => setSelectedContact(contact)}
          />
        )}

        {selectedContact.id !== linkedContact && (
          <ContactRow
            isSelected
            contact={selectedContact}
            onClick={(contact) => setSelectedContact(contact)}
          />
        )}

        {data &&
          !loading &&
          data?.representees
            ?.filter(
              (x) =>
                x.id !== linkedContact &&
                x.id !== selectedContact.id &&
                x.name.toLowerCase().includes(searchName.toLowerCase())
            )
            .slice(0, 6)
            .map((x, i) => {
              const contact = {
                id: x.id,
                contactId: x.contactId,
                userName: x.name,
                initials: x.name,
              };
              return (
                <ContactRow
                  key={i}
                  contact={contact}
                  onClick={(contact) => setSelectedContact(contact)}
                />
              );
            })}

        <div className="flex justify-end items-center p-2">
          <Button onClick={logOut}>{t("component.contactSelect.logOut")}</Button>
        </div>
      </div>
    </>
  );
};

const ContactRow = ({
  contact,
  onClick,
  isSelected,
}: {
  contact: SelectedContact;
  onClick: (contact: SelectedContact) => void;
  isSelected?: boolean;
}) => {
  return (
    <div
      onClick={() => onClick(contact)}
      className={classNames(
        "w-full min-w-[12rem] flex justify-between items-center p-3 gap-8 cursor-pointer hover:bg-gray-200 box-border",
        { "font-bold": isSelected }
      )}>
      {contact?.userName}
      <div className="flex justify-center items-center">
        {isSelected && <div className="w-2 h-2 bg-gray-800 rounded-full"></div>}
      </div>
    </div>
  );
};
