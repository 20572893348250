import { ComponentPropsWithoutRef, HTMLProps, ReactNode } from "react";
import { ReactComponent as Spinner } from "assets/spinner.svg";
import classNames from "classnames";

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  onClick?: () => void;
  children?: ReactNode;
  icon?: ReactNode;
  loading?: boolean;
}
export const Button = ({
  onClick,
  disabled = false,
  children,
  icon,
  loading,
  ...restProps
}: ButtonProps) => {
  return (
    <button
      onClick={() => {
        if (onClick) onClick();
      }}
      disabled={disabled}
      className={classNames(
        "py-1.5 px-3 rounded-lg bg-buttonColor text-white flex justify-center items-center gap-2 select-none",
        {
          "bg-companyColor/40 cursor-not-allowed": disabled,
        },
        { "cursor-default": !onClick && !disabled },
        restProps.className
      )}>
      {icon && loading ? (
        <Spinner className={classNames("w-4 h-4 text-companyColor animate-spin fill-white")} />
      ) : (
        icon
      )}
      {children}
    </button>
  );
};
