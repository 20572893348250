import { gql, useQuery } from "@apollo/client";
import { getFetchPolicyOptions } from "api/utils";

const CONTACT_QUERY = gql`
  query GetContactInfo($contactId: Long, $directOnly: Boolean) {
    contact(id: $contactId) {
      id
      name
      contactId
      representees(onlyDirectRepresentees: $directOnly) {
        name
        id
        contactId
      }
    }
  }
`;

export interface Contact {
  id: string | number;
  contactId: string;
  name: string;
  portfolios: Portfolio[];
  representees: Contact[];
}
interface Portfolio {
  id: number;
  name: string;
  shortName: string;
  currency: {
    securityCode: string;
  };
}
export interface GetContactsQuery {
  contact: Contact;
}

export const useGetRepresentees = (
  linkedContact: string | undefined,
  directRepresenteesOnly = true
) => {
  const { loading, error, data } = useQuery<GetContactsQuery>(CONTACT_QUERY, {
    variables: {
      contactId: linkedContact,
      directOnly: directRepresenteesOnly,
    },
    ...getFetchPolicyOptions(`useGetRepresentees.${linkedContact}.${directRepresenteesOnly}`),
  });
  return { loading, error, data: data?.contact };
};
